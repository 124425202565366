import React from 'react';
import PropTypes from 'prop-types';

import Attachments from 'components/common/attachments';
import { updateAttachmentSubscription } from 'components/common/subscriptions/utils/update-attachment-subscription';
import { updateSubscribeNewAttachment } from 'components/common/subscriptions/utils/update-subscribe-new-attachment';
import { updateAttachmentSubscribers } from 'components/common/subscriptions/utils/update-attachment-subscribers';

import Editor from '../../index';
import DescriptionToolbar from './description-toolbar';

import styles from '../../editor.module.scss';

const DescriptionEditor = ({
  onChange,
  value,
  placeholder,
  uploadProps,
  showItems,
  actionsDeps,
  attachmentsProps,
  isDisabled,
  isHtml,
  uploadFileComponent,
  resizeInput,
  destination,
  allowManageSubscribers,
  hideSubscribersAction,
  hideSubscribeAction
}) => {
  const onChangeFileList = files =>
    onChange({ ...value, fileList: files, isFromEditor: true });

  const onDeleteFile = file => {
    const newFiles = value.fileList.filter(({ id, uid }) =>
      id ? id !== file.id : uid !== file.uid
    );

    onChangeFileList(newFiles);
  };

  const onRenameFile = ({ id, name }) => {
    const newFileList = value.fileList.reduce((acc, curr) => {
      if (curr.response && curr.response.id === id) {
        return [...acc, { ...curr, name }];
      }

      return [...acc, curr];
    }, []);

    onChangeFileList(newFileList);
  };

  const handleSubscription = ({ data, isSubscribed }) =>
    updateAttachmentSubscription({
      attachments: value.fileList,
      setAttachments: onChangeFileList,
      data,
      isSubscribed
    });

  const onSubscribeToNewAttachment = ({ file, isSubscribed }) =>
    onChangeFileList(
      updateSubscribeNewAttachment({
        file,
        fileList: value.fileList,
        isSubscribed
      })
    );

  const handleAfterChangeManageSubscription = ({
    added,
    deleted,
    userId,
    entityId
  }) => {
    updateAttachmentSubscribers({
      attachments: value.fileList,
      setAttachments: onChangeFileList,
      data: { added, deleted, entityId, userId }
    });
  };

  return (
    <>
      <Editor
        fileList={value.fileList}
        onChangeFileList={onChangeFileList}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        showItems={showItems}
        isHtml={isHtml}
        defaultVisibleToolbar
        isDisabled={isDisabled}
        editorInputClassName={styles.descriptionEditorInput}
        resizeInput={resizeInput}
        bottomToolbar={
          <DescriptionToolbar
            fileList={value.fileList}
            value={value}
            onChangeFileList={onChangeFileList}
            showItems={showItems}
            uploadProps={{ ...uploadProps, uploadFileComponent }}
            actionsDeps={actionsDeps}
            isDisabled={isDisabled}
            resizeInput={resizeInput}
            attachmentProps={{
              allowManageSubscribers,
              hideSubscribersAction,
              hideSubscribeAction
            }}
          />
        }
        destination={{ ...destination, isDescription: true }}
      />

      {!uploadFileComponent && (
        <Attachments
          fileList={value.fileList}
          className={styles.attachments}
          {...attachmentsProps}
          attachmentProps={{
            size: 'small',
            onDelete: onDeleteFile,
            onRename: onRenameFile,
            isDisabled,
            fileLinkTarget: '_blank',
            ...(attachmentsProps.attachmentProps || {}),
            allowManageSubscribers,
            hideSubscribersAction,
            onSubscribeToNewAttachment,
            hideSubscribeAction
          }}
          actionsDeps={actionsDeps}
          subscribeCallback={handleSubscription}
          unsubscribeCallback={handleSubscription}
          onChangeFileList={onChangeFileList}
          changeManageSubscribersCallback={handleAfterChangeManageSubscription}
        />
      )}
    </>
  );
};

DescriptionEditor.propTypes = {
  uploadProps: PropTypes.object,
  attachmentsProps: PropTypes.object,
  showItems: PropTypes.shape({
    upload: PropTypes.bool,
    mention: PropTypes.bool,
    emoji: PropTypes.bool,
    topToolbar: PropTypes.bool
  }),
  isDisabled: PropTypes.bool,
  uploadFileComponent: PropTypes.node,
  resizeInput: PropTypes.bool,
  destination: PropTypes.object
};

DescriptionEditor.defaultProps = {
  uploadProps: undefined,
  attachmentsProps: {},
  showItems: {
    upload: true,
    mention: true,
    emoji: true,
    topToolbar: true
  },
  isDisabled: false,
  uploadFileComponent: undefined,
  resizeInput: false,
  destination: {}
};

export default DescriptionEditor;
