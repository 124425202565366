import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CONTACT } from 'constants/index';
import { LINK_TYPE } from 'constants/router';

import Typography from 'components/common/typography';
import ContactAvatar from 'components/common/avatar/contact';
import EmployeeAvatar from 'components/common/avatar/employee';
import ReplaceEmployeeOption from 'components/common/avatar/replace-employee-option';
import Tooltip from 'components/common/tooltip';

import { getFullName } from 'utils/get-fio';
import useRoutesService from 'services/routes';
import { useShowReplaceEmployee } from 'hooks/replacements/use-show-replace-employee';

import Option from '../option';

import styles from './user-option.module.scss';

export const UserOption = ({
  option,
  className,
  withWorkspace,
  withAllName,
  children,
  hidePosition,
  selectType,
  isLinkToElement,
  optionId,
  hasRelatedProject,
  ellipsis,
  isInInput,
  showEmail,
  isFilters,
  ...props
}) => {
  const { t } = useTranslation('Common', 'ReplaceAnEmployee');

  const {
    workspaceTitle,
    position,
    email,
    phone,
    isOnline,
    avatarFile,
    isProjectParticipant,
    isActive
  } = option;

  const workspace = workspaceTitle || position;
  const routes = useRoutesService({ returnUrl: true });

  const { replacerEmployee, isReplaced, tooltipData } = useShowReplaceEmployee({
    currentEmployee: option,
    isInInput
  });

  const name =
    typeof option === 'string'
      ? option
      : getFullName({ ...option, allName: !isReplaced && withAllName }) ||
        email ||
        phone ||
        children;

  const urlBySelectOption = {
    [LINK_TYPE.CONTACT]: routes.toContact({ id: optionId })
  };

  const title =
    withWorkspace && workspace && isInInput ? `${name} (${workspace})` : name;

  const UserInfo = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        width: !isInInput && '100%'
      }}
    >
      <div
        className={isReplaced && styles.filter}
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: hidePosition ? 0 : 4
        }}
      >
        {selectType === CONTACT && !isOnline ? (
          <ContactAvatar
            size={24}
            contact={option}
            style={{ marginRight: 8 }}
          />
        ) : (
          <EmployeeAvatar
            style={{ marginRight: 8, flexShrink: 0 }}
            employee={{ ...option, avatarFile, isActive }}
            tooltip={tooltipData}
          />
        )}

        <Tooltip title={title}>
          <Typography.Text
            className={classNames(isLinkToElement ? styles.textPrimary : '')}
            ellipsis={isInInput || ellipsis}
          >
            {title}
          </Typography.Text>
        </Tooltip>

        {hasRelatedProject && isProjectParticipant && (
          <Typography.Text color="black-55" style={{ marginLeft: 8 }}>
            {t('ProjectMember')}
          </Typography.Text>
        )}
      </div>

      {isReplaced && (
        <Tooltip title={t('Absent', { ns: 'ReplaceAnEmployee' })}>
          <Typography.Text color="red" ellipsis style={{ marginBottom: '4px' }}>
            {t('Absent', { ns: 'ReplaceAnEmployee' })}
          </Typography.Text>
        </Tooltip>
      )}
    </div>
  );

  return (
    <Option
      className={classNames(className, styles.root)}
      contentClassName={styles.optionContent}
      title={title}
      ellipsis={isInInput || ellipsis}
      {...props}
    >
      <div className={styles.content}>
        {isLinkToElement ? (
          <Link to={urlBySelectOption[selectType]}>
            <UserInfo />
          </Link>
        ) : (
          <UserInfo />
        )}

        {position && !hidePosition && (
          <Typography.Text
            ellipsis
            size="small"
            className={isReplaced && styles.filter}
          >
            {position}
          </Typography.Text>
        )}

        {email && showEmail && (
          <Typography.Text
            ellipsis
            size="small"
            className={isReplaced && styles.filter}
          >
            {email}
          </Typography.Text>
        )}

        {withWorkspace && !isInInput && (
          <Typography.Text color="black-55" size="small">
            {workspace}
          </Typography.Text>
        )}
      </div>

      {isReplaced && !isFilters && (
        <div className={styles.replaceInfo}>
          <ReplaceEmployeeOption replacer={replacerEmployee} />
        </div>
      )}
    </Option>
  );
};

UserOption.propTypes = {
  option: PropTypes.shape({
    avatarFile: PropTypes.object,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string
  }).isRequired,
  withWorkspace: PropTypes.bool,
  hidePosition: PropTypes.bool,
  withAllName: PropTypes.bool,
  isLinkToElement: PropTypes.bool,
  selectType: PropTypes.string,
  optionId: PropTypes.number,
  hasRelatedProject: PropTypes.bool,
  ellipsis: PropTypes.bool,
  showEmail: PropTypes.bool,
  isFilters: PropTypes.bool
};

UserOption.defaultProps = {
  withWorkspace: false,
  hidePosition: false,
  withAllName: true,
  isLinkToElement: false,
  selectType: '',
  optionId: null,
  hasRelatedProject: false,
  ellipsis: true,
  showEmail: false,
  isFilters: false
};

export default UserOption;
