import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FILE_TYPE_FILTER_OPTIONS } from 'constants/index';

import CustomSelect, {
  OrderSelect,
  ProjectSelect,
  TaskSelect,
  ContactSelect,
  EmployeeSelect,
  DateRangeSelect,
  AssetsSelect
} from 'components/common/controls/custom-select';
import Typography from 'components/common/typography';
import { TagsFilter } from 'components/common/tags';

import styles from './filters.module.scss';

export const Filters = ({ filters, actions }) => {
  const { t } = useTranslation(['Filters', 'Common', 'MyDrive']);

  const {
    orders,
    projects,
    tasks,
    contacts,
    creators,
    createdAt,
    asset,
    tag,
    fileType
  } = filters;
  const {
    setOrders,
    setProjects,
    setTasks,
    setContacts,
    setCreators,
    setCreatedAt,
    setAsset,
    setTag,
    setFileType
  } = actions;

  return (
    <>
      <EmployeeSelect
        label={t('FileAuthorFilters')}
        rootClassName={styles.filter}
        isMulti
        value={creators}
        onChange={setCreators}
        params={{
          excludeBench: false,
          isActive: null
        }}
        optionProps={{ isFilters: true }}
      />

      <DateRangeSelect
        label={t('DateFileAddedFilters')}
        rootClassName={styles.filter}
        value={createdAt}
        onChange={setCreatedAt}
      />

      <CustomSelect
        label={t('TitleFileType', { ns: 'MyDrive' })}
        isMulti
        closeMenuOnSelect={false}
        rootClassName={styles.filter}
        options={FILE_TYPE_FILTER_OPTIONS}
        value={fileType}
        onChange={setFileType}
        valueText={t('ChooseFileType', { ns: 'MyDrive' })}
      />

      <div className={styles.titleWrap}>
        <Typography.Title level={3}>{t('TagsFilters')}</Typography.Title>
      </div>

      <TagsFilter value={tag} className={styles.filter} onChange={setTag} />

      <div className={styles.titleWrap}>
        <Typography.Title level={3}>{t('LinksFilters')}</Typography.Title>
      </div>

      <ContactSelect
        label={t('ContactLinksFilters')}
        valueText={t('All', { ns: 'Common' })}
        rootClassName={styles.filter}
        value={contacts}
        onChange={setContacts}
        isMulti
        closeMenuOnSelect={false}
        addEntityButtonData={null}
      />

      <OrderSelect
        label={t('OrderLinksFilters')}
        rootClassName={styles.filter}
        value={orders}
        onChange={setOrders}
        isMulti
        closeMenuOnSelect={false}
        addEntityButtonData={null}
      />

      <ProjectSelect
        label={t('ProjectFilters')}
        rootClassName={styles.filter}
        value={projects}
        onChange={setProjects}
        isMulti
        closeMenuOnSelect={false}
        addEntityButtonData={null}
      />

      <TaskSelect
        label={t('TaskLinksFilters')}
        rootClassName={styles.filter}
        value={tasks}
        onChange={setTasks}
        isMulti
        closeMenuOnSelect={false}
        params={{
          access: 'full'
        }}
      />

      <AssetsSelect
        label={t('AssetLinksFilters')}
        valueText={t('All', { ns: 'Common' })}
        rootClassName={styles.filter}
        value={asset}
        addEntityButtonData={null}
        onChange={setAsset}
        isMulti
      />
    </>
  );
};

Filters.propTypes = {
  filters: PropTypes.shape({
    company: PropTypes.object,
    orders: PropTypes.array,
    projects: PropTypes.array,
    tasks: PropTypes.array,
    ideas: PropTypes.array,
    contacts: PropTypes.array
  }).isRequired,
  actions: PropTypes.shape({
    setCompany: PropTypes.func,
    setOrders: PropTypes.func,
    setProjects: PropTypes.func,
    setTasks: PropTypes.func,
    setIdeas: PropTypes.func,
    setContacts: PropTypes.func,
    onClear: PropTypes.func
  }).isRequired
};

export default Filters;
