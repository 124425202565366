import qs from 'qs';

import { makeCancelableRequest } from 'utils/make-cancalable-request';

export default api => ({
  fetch: makeCancelableRequest(
    api,
    (
      fetch,
      {
        workspaceId,
        range,
        responsible,
        creator,
        limit,
        offset,
        search,
        tagsCondition,
        tagsIds,
        task,
        orderStatus,
        asset,
        contact,
        exclude,
        category,
        id,
        isMySubscriptions
      }
    ) =>
      fetch('get', `/v1/${workspaceId}/assets/`, {
        params: {
          date_range: range,
          responsible,
          creator,
          limit,
          offset,
          search,
          tags_condition: tagsCondition,
          tags_ids: tagsIds,
          task,
          order_status: orderStatus,
          asset,
          contact,
          exclude,
          category,
          id,
          is_subscribed: isMySubscriptions
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' });
        }
      })
  ),

  fetchWithoutCancelling: ({
    workspaceId,
    range,
    responsible,
    creator,
    limit,
    offset,
    search,
    tagsCondition,
    tagsIds,
    task,
    orderStatus,
    asset,
    contact,
    exclude,
    category,
    id,
    isMySubscriptions
  }) =>
    api.get(`/v1/${workspaceId}/assets/`, {
      params: {
        date_range: range,
        responsible,
        creator,
        limit,
        offset,
        search,
        tags_condition: tagsCondition,
        tags_ids: tagsIds,
        task,
        order_status: orderStatus,
        asset,
        contact,
        exclude,
        category,
        id,
        is_subscribed: isMySubscriptions
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      }
    }),

  fetchOne: ({ workspaceId, id }) =>
    api.get(`/v1/${workspaceId}/assets/${id}/`, { show400: false }),

  create: ({ workspaceId, asset }) =>
    api.post(`/v1/${workspaceId}/assets/`, asset),

  update: ({ workspaceId, id, asset }) =>
    api.put(`/v1/${workspaceId}/assets/${id}/`, asset),

  partialUpdate: ({ workspaceId, id, asset }) =>
    api.patch(`/v1/${workspaceId}/assets/${id}/`, asset),

  delete: ({ workspaceId, id }) =>
    api.delete(`/v1/${workspaceId}/assets/${id}/`),

  // LOG & COMMENTS
  fetchLog: ({ workspaceId, id, params }) =>
    api.get(`/v1/${workspaceId}/assets/${id}/log/`, {
      params
    }),

  sendComment: ({ workspaceId, id, comment }) =>
    api.post(`/v1/${workspaceId}/assets/${id}/log/comments/`, comment),

  // MANAGERS
  fetchManagers: ({ workspaceId, params }) =>
    api.get(`/v1/${workspaceId}/assets-managers/`, {
      params,
      paramsSerializer(p) {
        return qs.stringify(p, { arrayFormat: 'comma' });
      }
    }),

  fetchOneManager: ({ workspaceId, id }) =>
    api.get(`/v1/${workspaceId}/assets-managers/${id}`),

  addManager: ({ workspaceId, values }) =>
    api.post(`/v1/${workspaceId}/assets-managers/`, {
      employee: values.employee,
      categories: values.categories
    }),

  deleteManager: ({ workspaceId, id, newManager }) =>
    api.delete(`/v1/${workspaceId}/assets-managers/${id}/`, {
      data: { newManager }
    }),

  changeManager: ({ workspaceId, id, newManager }) =>
    api.put(`/v1/${workspaceId}/assets-managers/${id}/`, {
      newManager
    }),

  // RELATIONS
  fetchRelations: ({ workspaceId, id }) =>
    api.get(`/v1/${workspaceId}/assets/${id}/relations/`, {
      params: {
        limit: 10000
      }
    }),

  changeRelations: ({ workspaceId, id, relations }) =>
    api.post(`/v1/${workspaceId}/assets/${id}/relations/bulk_update/`, {
      relations
    }),

  // MEMBERS (ACCESS SETTINGS)
  fetchMembers: ({ limit = 10000, offset = 0, id, workspaceId }) =>
    api.get(`/v1/${workspaceId}/assets/${id}/members/`, {
      params: {
        limit,
        offset
      }
    }),

  members: ({ employees, id, isDelete, workspaceId }) =>
    api.post(`/v1/${workspaceId}/assets/${id}/members/`, {
      employees,
      delete: isDelete
    }),

  fetchMembersInCategory: ({ limit = 10000, offset = 0, id, workspaceId }) =>
    api.get(`/v1/${workspaceId}/assets-categories/${id}/members/`, {
      params: {
        limit,
        offset
      }
    }),

  changeMembersInCategory: ({ employees, id, isDelete, workspaceId }) =>
    api.post(`/v1/${workspaceId}/assets-categories/${id}/members/`, {
      employees,
      delete: isDelete
    }),

  fetchCategories: ({ workspaceId, parent, search, id, manager, limit }) =>
    api.get(`/v1/${workspaceId}/assets-categories/`, {
      params: {
        parent,
        search,
        id,
        manager,
        limit
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      }
    }),

  fetchOneCategory: ({ workspaceId, id }) =>
    api.get(`/v1/${workspaceId}/assets-categories/${id}/`),

  addCategory: ({ workspaceId, category }) =>
    api.post(`/v1/${workspaceId}/assets-categories/`, category),

  deleteCategory: ({ workspaceId, id, newParent }) =>
    api.delete(`/v1/${workspaceId}/assets-categories/${id}/`, {
      data: { newParent }
    }),

  updateCategory: ({ workspaceId, id, category }) =>
    api.patch(`/v1/${workspaceId}/assets-categories/${id}/`, category)
});
