import { combineActions } from 'redux-actions';
// import { LOCATION_CHANGE } from 'connected-react-router';

import {
  EMIT_NEW_ORDER_CHAT,
  ORDER_UPDATED,
  ORDER_CREATED,
  CONTAINS_ANY_OPTION,
  STATUS_RECENT,
  OUTGOING,
  BOARD_ITEMS_PER_PAGE,
  ORDER_STATUS_ORDERING_OPTIONS,
  DEADLINE_AT,
  TYPE_ORDER_STATUS,
  TYPE_CONTACT,
  TYPE_MESSAGES,
  TYPE_ORDER,
  ALL
} from 'constants/index';

import { ORDER_COLLAPSED_CONTRACTOR_PANEL } from 'components/orders-view/drawers/details/contractor-panel/use-collapsed-contractor-panel';
import { getOrderStatusesInitialPageData } from 'components/orders-view/utils';
import { ORDER_SMALL_LIST_OPENED } from 'components/orders-view/drawers/details/small-list/use-small-list-opened';

import { setActiveId } from 'store/workspace';
// import { makeSubscribeLocationChange } from 'store/router';
import {
  // createAssetRecord,
  fetchAssetOrderStatusBookingData,
  updateAssetRecord
} from 'store/calendar';
import { combineActionsThunk } from 'store/actions-thunk';

import handleActions from 'utils/redux-actions';
// import { getInitialValueFilterStorage } from 'hooks/common/use-filter-storage';
import { getInitialValueStorage } from 'hooks';

import {
  fetchOrders,
  createOrder,
  editOrder,
  setCurrentPage,
  clearFilter,
  handleFavoriteOrder,
  fetchOrder,
  createPaymentlog,
  updatePaymentlog,
  deletePaymentlog,
  addNegotiation,
  createDeal,
  convertExtended,
  createWorklog,
  fetchWorklogList,
  deleteWorklog,
  updateWorklog,
  createFeedbacks,
  addUsersNegotiation,
  fetchSchedulerConfig,
  addSchedulerAct,
  setCollapsedOrderStatusContractorPanel,
  remove,
  changeOrderStatusAttachments,
  setOrderStatusesFilterKind,
  fetchOrderStatus,
  fetchOrderStatusContractor,
  fetchOrderStatusResponsible,
  fetchOrderStatusSignatory,
  clearSelectedOrderStatus,
  fetchOrderStatusAttachments,
  fetchOrderStatuses,
  clearOrderStatusesEntries,
  fetchOrderStatusesContractors,
  reorderOrderStatusesEntries,
  resetReorderingOrderStatusesEntries,
  editOrderStatusContractorSync,
  removeOrderStatusFromEntries,
  setOrderStatusesIsAllLoading,
  fetchOrderStatusesResponsibles,
  setOpenedOrderStatusSmallList,
  setOrderStatusesOrdering,
  setOrderStatusesFilterResponsibleIds,
  setOrderStatusesFilterSignatoryIds,
  setOrderStatusesFilterMemberIds,
  setOrderStatusesFilterTag,
  setOrderStatusesFilterCreatedAtRange,
  setOrderStatusesFilterStartAtRange,
  setOrderStatusesFilterDeadlineAtRange,
  setOrderStatusesFilterCompletedAtRange,
  setOrderStatusesFilterSearch,
  fetchOrderStatusRelations,
  setOrderStatusesFilterRelations,
  setOrderStatusesFilterContractorIds,
  setOrderStatusesFilterCompanies,
  fetchOrderStatusAllActs,
  fetchOrderStatusSpecification,
  setOrderStatusesFilterSearchKind,
  fetchOrderOnContacts,
  setSelectedContactId,
  clearOrderStatusesContactsEntries,
  setSelectedContactOrdering
} from './actions';
import {
  subscribeAsset,
  subscribeAttachment,
  unsubscribeAsset,
  unsubscribeAttachment
} from '../subscriptions';

export const ORDER_STATUS_SEARCH_KIND_OPTIONS = [
  {
    value: ALL.toUpperCase(),
    label: 'SearchEverything',
    ns: 'Orders'
  },
  {
    value: TYPE_ORDER.toUpperCase(),
    label: 'SearchOrders',
    ns: 'Orders'
  },
  {
    value: TYPE_CONTACT.toUpperCase(),
    label: 'SearchContacts',
    ns: 'Orders'
  },
  {
    value: TYPE_MESSAGES.toUpperCase(),
    label: 'SearchMessages',
    ns: 'Orders'
  }
];

export const ORDER_STATUS_SEARCH_KIND_DEFAULT_OPTION =
  ORDER_STATUS_SEARCH_KIND_OPTIONS[0];

const getDefaultOrdering = () => {
  const deadlineAtOrdering = ORDER_STATUS_ORDERING_OPTIONS.find(
    o => o.key === DEADLINE_AT
  );

  return deadlineAtOrdering;
};

export const initialFilter = {
  kind: OUTGOING,
  ordering: getDefaultOrdering(),
  responsibleIds: [],
  signatoryIds: [],
  memberIds: [],
  tag: {
    ids: [],
    condition: CONTAINS_ANY_OPTION
  },
  createdAtRange: {},
  startAtRange: {},
  deadlineAtRange: {},
  completedAtRange: {},
  search: '',
  searchKind: ORDER_STATUS_SEARCH_KIND_DEFAULT_OPTION,
  relations: {
    task: [],
    orderStatus: [],
    asset: [],
    contact: []
  },
  companies: [],
  contractorIds: []
};

const initialState = {
  isAllLoading: false, // loading all statuses on the board

  currentPage: 1, // delete

  filter: {
    ...initialFilter
    // ...getInitialValueFilterStorage(ORDERS, initialFilter)
  },

  selected: null,
  collapsedContractorPanel: getInitialValueStorage(
    ORDER_COLLAPSED_CONTRACTOR_PANEL,
    false
  ),
  smallListOpened: getInitialValueStorage(ORDER_SMALL_LIST_OPENED, false),
  isCanSubmitSms: false,

  itemsPerPage: BOARD_ITEMS_PER_PAGE,
  pageData: getOrderStatusesInitialPageData(),

  entries: [],

  newOrders: [], // new orders from socket event (v1)

  contacts: {
    entries: [],
    selectedContactId: null,
    totalItems: 0,
    ordering: getDefaultOrdering()
  }
};

export default handleActions(
  {
    // [LOCATION_CHANGE]: makeSubscribeLocationChange(CRM, ORDERS),

    [setActiveId]: () => initialState,

    [combineActions(
      setOrderStatusesFilterKind,
      setOrderStatusesOrdering,
      setOrderStatusesFilterResponsibleIds,
      setOrderStatusesFilterSignatoryIds,
      setOrderStatusesFilterMemberIds,
      setOrderStatusesFilterTag,
      setOrderStatusesFilterCreatedAtRange,
      setOrderStatusesFilterStartAtRange,
      setOrderStatusesFilterDeadlineAtRange,
      setOrderStatusesFilterCompletedAtRange,
      setOrderStatusesFilterSearch,
      setOrderStatusesFilterRelations,
      setOrderStatusesFilterCompanies,
      setOrderStatusesFilterContractorIds,
      setOrderStatusesFilterSearchKind
    )]: state => {
      state.pageData = getOrderStatusesInitialPageData();
      state.entries = [];

      return state;
    },

    [setOrderStatusesFilterCompanies]: (state, { payload }) => {
      state.filter.companies = payload;

      return state;
    },

    [setOrderStatusesFilterContractorIds]: (state, { payload }) => {
      state.filter.contractorIds = payload;

      return state;
    },

    [setOrderStatusesFilterRelations]: (state, { payload }) => {
      state.filter.relations[payload.key] = payload.value;

      return state;
    },

    [setOrderStatusesFilterSearch]: (state, { payload }) => {
      state.filter.search = payload;

      return state;
    },

    [setOrderStatusesFilterSearchKind]: (state, { payload }) => {
      state.filter.searchKind = payload;

      return state;
    },

    [setOrderStatusesFilterCompletedAtRange]: (state, { payload }) => {
      state.filter.completedAtRange = payload;

      return state;
    },

    [setOrderStatusesFilterDeadlineAtRange]: (state, { payload }) => {
      state.filter.deadlineAtRange = payload;

      return state;
    },

    [setOrderStatusesFilterStartAtRange]: (state, { payload }) => {
      state.filter.startAtRange = payload;

      return state;
    },

    [setOrderStatusesFilterCreatedAtRange]: (state, { payload }) => {
      state.filter.createdAtRange = payload;

      return state;
    },

    [setOrderStatusesFilterTag]: (state, { payload }) => {
      state.filter.tag = payload;

      return state;
    },

    [setOrderStatusesFilterMemberIds]: (state, { payload }) => {
      state.filter.memberIds = payload;

      return state;
    },

    [setOrderStatusesFilterSignatoryIds]: (state, { payload }) => {
      state.filter.signatoryIds = payload;

      return state;
    },

    [setOrderStatusesFilterResponsibleIds]: (state, { payload }) => {
      state.filter.responsibleIds = payload;

      return state;
    },

    [setOrderStatusesFilterKind]: (state, { payload }) => {
      state.filter.kind = payload;

      return state;
    },

    [setOrderStatusesOrdering]: (state, { payload }) => {
      state.filter.ordering = payload;

      return state;
    },

    [setCollapsedOrderStatusContractorPanel]: (state, { payload }) => {
      state.collapsedContractorPanel = payload;

      return state;
    },

    [setOpenedOrderStatusSmallList]: (state, { payload }) => {
      state.smallListOpened = payload;

      return state;
    },

    [setCurrentPage]: (state, { payload }) => {
      state.currentPage = payload;

      return state;
    },

    [clearFilter]: state => {
      state.filter = {
        ...initialFilter,
        ordering: state.filter.ordering,
        kind: state.filter.kind
      };

      return state;
    },

    [fetchOrders.FAILED]: (state, { payload }) => {
      state.error = payload;

      return state;
    },

    [fetchOrders.SUCCEEDED]: (state, { payload }) => {
      if (state.filter.search !== payload.search) {
        return state;
      }

      state.totalItems = payload.totalItems;
      state.entries = [...state.entries, ...payload.results];
      state.error = null;

      return state;
    },

    [ORDER_CREATED]: (state, { payload }) => {
      if (+localStorage.getItem('workspace') === payload.workspaceId) {
        const isExists = state.newOrders.some(
          ({ id }) => id === payload.orderStatusId
        );

        if (!isExists) {
          state.newOrders = [payload.orderStatusId, ...state.newOrders];
        }
      }

      return state;
    },

    [combineActions(createOrder.SUCCEEDED, fetchOrder.SUCCEEDED)]: (
      state,
      { payload }
    ) => {
      const index = state.entries.findIndex(
        orderStatus => orderStatus.id === payload.id
      );

      if (index !== -1) {
        state.entries[index] = {
          ...payload,
          relations: state.entries[index].relations
        };
      } else {
        state.entries = [payload, ...state.entries];
      }

      return state;
    },

    [fetchOrder.FAILED]: (state, { args }) => {
      state.entries = state.entries.filter(
        orderStatus => orderStatus.id !== args.statusId
      );

      return state;
    },

    [editOrder.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        order => order.order.id === payload.order.id
      );

      if (!state.entries[index]) return state;

      Object.keys(payload.order).forEach(key => {
        state.entries[index].order[key] = payload.order[key];
      });

      state.entries[index].controller = payload.controller;
      state.entries[index].employee = payload.employee;
      state.entries[index].permissions = payload.permissions;
      state.entries[index].isTaskModalShowed = payload.isTaskModalShowed;

      return state;
    },

    [remove.SUCCEEDED]: (state, { args }) => {
      state.totalItems -= 1;
      const newEntries = state.entries.filter(e => e.id !== args.orderStatusId);
      state.entries = newEntries;

      return state;
    },

    [createPaymentlog.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        status => status.id === payload.statusId
      );

      if (index === -1) {
        return state;
      }

      state.entries[index].paid -= -payload.value;
      state.entries[index].paid = state.entries[index].paid.toFixed(2);

      return state;
    },

    [updatePaymentlog.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        status => status.id === payload.statusId
      );

      if (index === -1) {
        return state;
      }

      state.entries[index].paid -= -payload.diff;
      state.entries[index].paid = state.entries[index].paid.toFixed(2);

      return state;
    },

    [deletePaymentlog.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        status => status.id === payload.statusId
      );

      if (index === -1) {
        return state;
      }

      state.entries[index].paid -= payload.value;
      state.entries[index].paid = state.entries[index].paid.toFixed(2);

      return state;
    },

    [handleFavoriteOrder.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(status => status.id === payload.id);

      if (index === -1) {
        return state;
      }

      state.entries[index].isFavorite = payload.isFavorite;

      return state;
    },

    [createFeedbacks.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        status => status.id === payload.statusId
      );

      if (index === -1) {
        return state;
      }

      state.entries[index].isFeedbackExists = true;

      return state;
    },

    [createWorklog.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(status => status.id === payload.id);

      if (index === -1) {
        return state;
      }

      state.entries[index].workLog += payload.value;

      return state;
    },

    [fetchWorklogList.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(item => item.id === payload.id);

      if (index === -1) {
        return state;
      }

      state.entries[index].worklogList = payload.results;

      return state;
    },

    [deleteWorklog.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        item => item.id === payload.orderStatusId
      );

      if (index === -1) {
        return state;
      }

      const worklogList = state.entries[index].worklogList || [];
      state.entries[index].workLog -= payload.value;
      state.entries[index].workLogTotal -= payload.value;
      state.entries[index].worklogList = worklogList.filter(
        ({ id }) => id !== payload.worklogId
      );

      return state;
    },

    [updateWorklog.SUCCEEDED]: (state, { payload }) => {
      const getIndexById = (id, list) =>
        (list || []).findIndex(item => item.id === id);

      const index = getIndexById(payload.orderStatusId, state.entries);

      if (index === -1) {
        return state;
      }

      const indexWorklog = getIndexById(
        payload.id,
        state.entries[index].worklogList
      );

      if (indexWorklog !== -1) {
        state.entries[index].worklogList[indexWorklog] = payload;
      }

      state.entries[index].workLog += payload.diff;

      return state;
    },

    [EMIT_NEW_ORDER_CHAT]: (state, { payload }) => {
      const index = state.entries.findIndex(
        order => order.id === payload.order
      );

      if (index === -1) {
        return state;
      }

      state.entries[index].status = STATUS_RECENT;

      return state;
    },

    // Socket. Обновляет статус заказа (удаление, просмотр)
    [ORDER_UPDATED]: (state, { payload }) => {
      const index = state.entries.findIndex(status => status.id === payload.id);

      Object.keys(payload).forEach(key => {
        if (state.entries[index]) {
          if (key === 'isLead') {
            state.entries[index].order[key] = payload[key];
          }

          state.entries[index][key] = payload[key];
        }
      });

      return state;
    },

    [createDeal.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        status => status.id === payload.statusId
      );

      if (index === -1) {
        return state;
      }

      state.entries[index].contractor = payload.contractor;

      return state;
    },

    [combineActions(addNegotiation.SUCCEEDED, addUsersNegotiation.SUCCEEDED)]: (
      state,
      { payload, args }
    ) => {
      if (+payload.priceTotal === 0 && payload.isFirstNegotiate) {
        const index = state.entries.findIndex(
          status => status.id === (args || {}).id
        );

        state.entries[index].negotiation = payload;
      }

      return state;
    },

    [fetchSchedulerConfig.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        status => status.id === payload.statusId
      );

      if (index !== -1) {
        state.entries[index].schedulerConfig = payload.config;
      }

      return state;
    },

    [addSchedulerAct.SUCCEEDED]: (state, { payload }) => {
      if (payload.withoutAccept) {
        const index = state.entries.findIndex(
          status => status.id === payload.data.orderStatus
        );

        if (index !== -1) {
          state.entries[index].schedulerConfig = payload.data;
        }
      }

      return state;
    },

    [convertExtended.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(status => status.id === payload.id);

      if (index !== -1) {
        state.entries[index] = payload;
      }

      return state;
    },

    // V2

    [fetchOrderStatuses.SUCCEEDED]: (state, { args, payload }) => {
      const { _embedded, page } = payload;

      if (_embedded) {
        if (!Array.isArray(args.statuses)) {
          state.pageData[args.statuses] = page;
        }

        state.entries = [...state.entries, ..._embedded.orderStatuses];
      }

      return state;
    },

    [fetchOrderStatusAllActs.SUCCEEDED]: (state, { payload }) => {
      const { _embedded } = payload;

      if (state.selected && _embedded) {
        state.selected.acts = _embedded.acts;
      }

      return state;
    },

    [fetchOrderStatusSpecification.SUCCEEDED]: (state, { payload }) => {
      if (state.selected) {
        state.selected.specification = payload;
      }

      return state;
    },

    [fetchOrderStatus.SUCCEEDED]: (state, { payload }) => {
      if (!state.selected) {
        state.selected = {};
      }

      Object.keys(payload).forEach(key => {
        state.selected[key] = payload[key];
      });

      // TODO: replace logic after socket implementation
      const entryIndex = state.entries.findIndex(
        entry => entry.id === payload.id
      );

      if (entryIndex !== -1) {
        const prevState =
          state.entries[entryIndex].realState || // check reorderOrderStatusesEntries reducer
          state.entries[entryIndex].state;
        const newState = payload.state;

        if (prevState !== newState || state.entries[entryIndex].realState) {
          state.pageData[prevState].totalElements -= 1;
          state.pageData[newState].totalElements += 1;
        }

        Object.keys(payload).forEach(key => {
          state.entries[entryIndex][key] = payload[key];
        });

        state.entries[entryIndex].realState = null;
      }

      const contactIndex = state.contacts.entries.findIndex(entry => entry.id === payload.id);

      if (contactIndex !== -1) {
        Object.keys(payload).forEach(key => {
          state.contacts.entries[contactIndex][key] = payload[key];
        });

        state.contacts.entries[contactIndex].realState = null;
      }

      return state;
    },

    [fetchOrderStatusContractor.SUCCEEDED]: (state, { payload }) => {
      if (state.selected) {
        state.selected.contractor = payload;
      }

      return state;
    },

    [fetchOrderStatusResponsible.SUCCEEDED]: (state, { payload }) => {
      if (state.selected) {
        state.selected.responsible = payload;
      }

      return state;
    },

    [fetchOrderStatusSignatory.SUCCEEDED]: (state, { payload }) => {
      if (state.selected) {
        state.selected.signatory = payload;
      }

      return state;
    },

    [fetchOrderStatusAttachments.SUCCEEDED]: (state, { args, payload }) => {
      if (!args.params.fileIds && state.selected) {
        state.selected.documentList = payload.results.filter(
          ({ isTrash }) => !isTrash
        );
      }

      return state;
    },

    [fetchOrderStatusesContractors.SUCCEEDED]: (state, { args, payload }) => {
      args.orderStatusIds.forEach(id => {
        const index = state.entries.findIndex(entry => entry.id === id);

        if (index !== -1) {
          const contractor = payload.results.find(
            contact => contact.id === state.entries[index].contractorId
          );

          state.entries[index] = {
            ...state.entries[index],
            contractor
          };
        }
      });

      return state;
    },

    [fetchOrderStatusesResponsibles.SUCCEEDED]: (state, { args, payload }) => {
      args.orderStatusIds.forEach(id => {
        const index = state.entries.findIndex(entry => entry.id === id);

        if (index !== -1) {
          const responsible = payload.results.find(
            resp => resp.id === state.entries[index].responsibleId
          );

          state.entries[index] = {
            ...state.entries[index],
            responsible
          };
        }
      });

      return state;
    },

    [fetchOrderStatusRelations.SUCCEEDED]: (state, { payload }) => {
      if (state.selected) {
        state.selected.relations = payload;
      }

      return state;
    },

    // CALENDAR
    [combineActionsThunk(
      // createAssetRecord.SUCCEEDED,
      updateAssetRecord.SUCCEEDED
    )]: (state, { args }) => {
      if (state.selected && args.record.kind === TYPE_ORDER_STATUS) {
        state.selected.startAt = args.record.dateStart;
        state.selected.deadlineAt = args.record.dateEnd;
      }

      return state;
    },

    [fetchAssetOrderStatusBookingData.SUCCEEDED]: (state, { payload }) => {
      if (state.selected) {
        state.selected.bookings = payload;
      }

      return state;
    },

    [clearSelectedOrderStatus]: state => {
      state.selected = null;

      return state;
    },

    [clearOrderStatusesEntries]: (state, { payload }) => {
      if (!payload) {
        state.entries = [];
      }

      state.entries = state.entries.filter(entry => entry.state !== payload);

      return state;
    },
    [clearOrderStatusesContactsEntries]: (state, { payload }) => {
      if (!payload) {
        state.contacts.entries = [];
      }

      state.contacts.entries = state.contacts.entries.filter(
        entry => entry.state !== payload
      );

      return state;
    },

    [reorderOrderStatusesEntries]: (state, { payload }) => {
      const result = [...state.entries];

      const [removed] = result.splice(payload.startIndex, 1);
      result.splice(payload.endIndex, 0, {
        ...removed,
        // используется для сохранения реального стейта, пока не выполнен запрос на перемещение (на борде)
        // state заменяю для того, чтобы отработал фильтр и закинул заказ в нужную колонку
        realState: removed.state,
        state: payload.newState
      });

      state.entries = result;

      return state;
    },

    [setOrderStatusesIsAllLoading]: (state, { payload }) => {
      state.isAllLoading = payload;

      return state;
    },

    [resetReorderingOrderStatusesEntries]: (state, { payload }) => {
      state.entries = payload;

      return state;
    },

    [removeOrderStatusFromEntries]: (state, { payload }) => {
      state.entries = state.entries.filter(
        orderStatus => orderStatus.id !== payload
      );

      return state;
    },

    [editOrderStatusContractorSync]: (state, { payload }) => {
      const { id, values } = payload;

      Object.keys(values).forEach(key => {
        state.selected.contractor[key] = values[key];
      });

      const index = state.entries.findIndex(entry => entry.id === id);

      if (index !== -1) {
        Object.keys(values).forEach(key => {
          state.entries[index].contractor[key] = values[key];
        });
      }

      return state;
    },

    [changeOrderStatusAttachments]: (state, { payload }) => {
      const { attachment, isDelete } = payload;

      if (state.selected) {
        if (isDelete) {
          state.selected.fileList = state.selected.fileList.filter(
            fileId => fileId !== attachment.fileId
          );
          state.selected.documentList = state.selected.documentList.filter(
            doc => doc.fileId !== attachment.fileId
          );
        } else {
          state.selected.fileList.push(attachment.fileId);
          state.selected.documentList.push(attachment);
        }
      }

      return state;
    },

    [fetchOrderOnContacts.SUCCEEDED]: (state, { payload }) => {
      const { entries = [], contactId, totalItems } = payload;
      const { selectedContactId, entries: currentEntries } = state.contacts;

      if (contactId !== selectedContactId || currentEntries.length === 0) {
        state.contacts.entries = [];
      }

      const uniqueNewEntries = entries.filter(
        newEntry =>
          !currentEntries.some(
            existingEntry => existingEntry.id === newEntry.id
          )
      );

      state.contacts.entries = [...currentEntries, ...uniqueNewEntries];
      state.contacts.totalItems = totalItems;

      if (contactId) {
        state.contacts.selectedContactId = contactId;
      }
    },

    [setSelectedContactId]: (state, { payload }) => {
      state.contacts.entries = [];

      state.contacts.selectedContactId = payload;
    },

    [setSelectedContactOrdering]: (state, { payload }) => {
      state.contacts.ordering = payload;

      return state;
    },

    [combineActions(
      subscribeAttachment.SUCCEEDED,
      unsubscribeAttachment.SUCCEEDED
    )]: (state, { payload }) => {
      if (state.selected) {
        const fileList = state.selected.documentList || [];

        const fileIndex = fileList.findIndex(
          file => file.fileId === payload.entityId
        );

        if (fileIndex !== -1) {
          fileList[fileIndex] = {
            ...fileList[fileIndex],
            isSubscribed: !fileList[fileIndex].isSubscribed
          };
        }

        state.selected.documentList = fileList;
      }

      return state;
    },

    [combineActions(subscribeAsset.SUCCEEDED, unsubscribeAsset.SUCCEEDED)]: (
      state,
      { payload }
    ) => {
      if (state.selected) {
        const relations = state.selected.relations || [];

        const relationIndex = relations.findIndex(
          relation => relation.objectId === Number(payload.entityId)
        );

        if (relationIndex !== -1) {
          relations[relationIndex] = {
            ...relations[relationIndex],
            relation: {
              ...relations[relationIndex].relation,
              isSubscribed: !relations[relationIndex].relation.isSubscribed
            }
          };
        }

        state.selected.relations = relations;
      }

      return state;
    }
  },
  initialState
);
