import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {
  fetchOrderStatus,
  fetchOrderStatusAttachments,
  editOrderStatus,
  fetchOrderStatusSignatory,
} from 'store/order-statuses';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

const useEdit = ({
  orderStatusId,
  onClose,
  visible,
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [values, setValues] = useState({});

  const fetchOrderData = useCallback(async () => {
    try {
      setFetchLoading(true);

      const orderStatus = await dispatch(
        fetchOrderStatus({ id: orderStatusId })
      );

      const haveSignatory = !!orderStatus.signatoryId;

      let signatoryData = {};
      if (haveSignatory) {
        signatoryData = await dispatch(
          fetchOrderStatusSignatory({ signatoryId: orderStatus.signatoryId })
        );
      }

      const { results: documentList } = await dispatch(
        fetchOrderStatusAttachments({
          params: {
            relatedOrderStatuses: orderStatusId
          }
        })
      );

      setValues({
        ...orderStatus,
        signatory: haveSignatory && signatoryData,
        description: orderStatus.content,
        fileList: documentList
      });
    } finally {
      setFetchLoading(false);
    }
  }, [dispatch, orderStatusId]);

  useEffect(() => {
    if (visible) {
      fetchOrderData();
    }
    return () => setValues({});
  }, [fetchOrderData, visible]);

  const onSubmit = useCallback(
    async newValues => {
      try {
        setIsLoading(true);

        await dispatch(
          editOrderStatus({
            id: orderStatusId,
            values: newValues
          })
        );

        await dispatch(fetchOrderStatus({ id: orderStatusId }));

        showNoticeMessage({ number: NOTICE_NUMBER.orderStatusUpdated });

        onClose();
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderStatusId]
  );

  const hasValues = !isEmpty(values) && !fetchLoading;

  return {
    isLoading,
    values,
    hasValues,
    onSubmit
  };
};

export default useEdit;
