import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TYPE_ACQUAINTANCE } from 'constants/index';

import Drawer from 'components/common/drawer';
import Attachments from 'components/common/attachments';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import { updateAttachmentSubscription } from 'components/common/subscriptions/utils/update-attachment-subscription';
import { updateAttachmentSubscribers } from 'components/common/subscriptions/utils/update-attachment-subscribers';

import {
  fetchAcquaintenceSheet,
  fetchTaskLocal,
  uploadAcquaintenceSheetFile
} from 'store/tasks';

import getUrlFileByArrayBinary from 'hooks/common/use-file-upload/get-url-file-by-binaty';
import downloadFile from 'hooks/common/use-file-upload/download-file';
import { getFullName } from 'utils/get-fio';

import Table from './table';

export const AcquaintenceSheetDrawer = ({
  visible,
  task,
  onClose,
  ...props
}) => {
  const [afterChangeVisible, setAfterChangeVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [data, setData] = useState({});
  const [taskId, setTaskId] = useState(null);

  const dispatch = useDispatch();

  const { t } = useTranslation('IntroductionList');

  const { attachments, steps } = data;

  const fetchData = async () => {
    try {
      setIsLoading(true);
      let resultId = task.id;

      if (task.parent) {
        const parentTask = await dispatch(fetchTaskLocal({ id: task.parent }));

        if (parentTask.kind === TYPE_ACQUAINTANCE) {
          resultId = task.parent;
        }
      }

      const { data: sheetData } = await dispatch(
        fetchAcquaintenceSheet({
          id: resultId
        })
      );
      setData(sheetData);
      setTaskId(resultId);
    } finally {
      setIsLoading(false);
    }
  };

  const onDownloadFile = async () => {
    try {
      setIsFileLoading(true);

      const { data: file } = await dispatch(
        uploadAcquaintenceSheetFile({ id: taskId })
      );

      const url = getUrlFileByArrayBinary(file);
      downloadFile({ url, fileName: file.filename });
    } finally {
      setIsFileLoading(false);
    }
  };

  const handleSubscription = ({ data: subscriptionData, isSubscribed }) =>
    updateAttachmentSubscription({
      attachments,
      setAttachments: updatedFiles =>
        setData({ ...data, attachments: updatedFiles }),
      data: subscriptionData,
      isSubscribed
    });

  const handleAfterChangeManageSubscription = ({
    added,
    deleted,
    userId,
    entityId
  }) => {
    updateAttachmentSubscribers({
      attachments,
      setAttachments: updatedFiles =>
        setData({ ...data, attachments: updatedFiles }),
      data: { added, deleted, entityId, userId }
    });
  };

  useEffect(() => {
    if (afterChangeVisible) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterChangeVisible]);

  return (
    <Drawer
      title={<Drawer.Title>{t('IntroductionList')}</Drawer.Title>}
      data-qa="qa-dpwb2lwrn1j9pdu"
      width={800}
      visible={visible}
      afterVisibleChange={setAfterChangeVisible}
      destroyOnClose
      onClose={onClose}
      {...props}
    >
      {!!(attachments || []).length && (
        <>
          <Typography.Title level={3} style={{ marginBottom: 16 }}>
            {t('IntroductionDoc')}
          </Typography.Title>

          <div style={{ marginBottom: 24 }}>
            <Attachments
              fileList={attachments}
              subscribeCallback={handleSubscription}
              unsubscribeCallback={handleSubscription}
              changeManageSubscribersCallback={
                handleAfterChangeManageSubscription
              }
            />
          </div>
        </>
      )}

      <Typography.Paragraph style={{ marginBottom: 24 }}>{`${t(
        'IntroductionInitiator'
      )} ${getFullName(task.author)}`}</Typography.Paragraph>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          height: 'calc(100% - 44px)'
        }}
        data-qa="qa-jyd52prumyaib1e"
      >
        <Table isLoading={isLoading} dataSource={steps} />

        <Button
          type="primary"
          size="large"
          style={{ flexShrink: 0 }}
          loading={isFileLoading}
          onClick={onDownloadFile}
        >
          {t('DownloadIntroductionListBtn')}
        </Button>
      </div>
    </Drawer>
  );
};

AcquaintenceSheetDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  task: PropTypes.shape({
    id: PropTypes.number,
    author: PropTypes.object
  }),
  onClose: PropTypes.func.isRequired
};

AcquaintenceSheetDrawer.defaultProps = {
  task: undefined
};

export default AcquaintenceSheetDrawer;
