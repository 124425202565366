import unionBy from 'lodash/unionBy';
import { combineActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  ASSETS,
  CONTAINS_ANY_OPTION,
  CATEGORIES,
  TYPE_ASSET
} from 'constants/index';

import { makeSubscribeLocationChange } from 'store/router';

import { getInitialValueFilterStorage } from 'hooks/common/use-filter-storage';
import handleActions from 'utils/redux-actions';

import {
  clearFilter,
  setFilterSearch,
  fetchOne,
  create,
  update,
  partialUpdate,
  remove,
  fetchLog,
  sendComment,
  fetchManagers,
  addManager,
  setFilterRange,
  setFilterResponsible,
  setFilterCreator,
  changeAssetAttachments,
  setFilterTag,
  setFilterRelations,
  setFilterCategories,
  fetchCategories,
  addCategory,
  updateCategory,
  deleteCategory,
  clearEntries,
  fetchAssets,
  setFilterManagers,
  changeManager,
  setFilterMySubscriptions
} from './actions';
import {
  changeManageSubscribers,
  subscribeAsset,
  unsubscribeAsset
} from '../subscriptions';

export const initialFilter = {
  search: '',
  responsible: [],
  creator: [],
  range: {},
  tag: {
    ids: [],
    condition: CONTAINS_ANY_OPTION
  },
  relations: {
    task: [],
    orderStatus: [],
    asset: [],
    contact: []
  },
  category: [],
  manager: [],
  isMySubscriptions: false
};

const initialState = {
  filter: {
    ...initialFilter,
    ...getInitialValueFilterStorage(ASSETS, initialFilter)
  },
  isLoading: false,

  categories: {
    all: [],
    entries: []
  },

  managers: [],

  assets: {
    totalItems: 0,
    entries: []
  }
};

export default handleActions(
  {
    [LOCATION_CHANGE]: makeSubscribeLocationChange(ASSETS, CATEGORIES),

    [fetchAssets.START]: state => {
      state.isLoading = true;

      return state;
    },

    [fetchAssets.SUCCEEDED]: (state, { payload, args }) => {
      state.assets.totalItems = payload.count;
      state.assets.entries = args.withReplaceEntries
        ? payload.results
        : unionBy([...state.assets.entries], [...payload.results], 'id');

      state.isLoading = false;
      return state;
    },

    [fetchCategories.SUCCEEDED]: (state, { args, payload }) => {
      if (args.isAll) {
        state.categories.all = payload;
      } else {
        state.categories.entries = payload;
      }

      return state;
    },

    [fetchAssets.ENDED]: state => {
      state.isLoading = initialState.isLoading;

      return state;
    },

    [fetchOne.SUCCEEDED]: (state, { payload }) => {
      const index = state.assets.entries.findIndex(a => a.id === payload.id);

      if (index !== -1) {
        state.assets.entries[index] = {
          ...payload,
          log: state.assets.entries[index].log,
          relations: state.assets.entries[index].relations
        };
      } else {
        state.assets.entries = [payload, ...state.assets.entries];
      }

      return state;
    },

    [create.SUCCEEDED]: (state, { payload, args }) => {
      const index = state.categories.entries.findIndex(
        c => c.id === payload.category.id
      );

      if (index !== -1) {
        state.categories.entries[index].assetsCount += 1;
      }

      if (args.categoryId === payload.category.id) {
        state.assets.totalItems += 1;
        state.assets.entries = [payload, ...state.assets.entries];
      }

      return state;
    },

    [addCategory.SUCCEEDED]: (state, { payload, args }) => {
      const index = state.categories.entries.findIndex(
        c => c.parentId === payload.parentId
      );

      if (payload.parentId === args.categoryId || index !== -1) {
        state.categories.entries = [...state.categories.entries, payload];
      }

      state.categories.entries = state.categories.entries.map(item => {
        if (item.id === payload.parentId) {
          return {
            ...item,
            subcategoriesCount: item.subcategoriesCount + 1
          };
        }

        return item;
      });

      state.categories.all.push(payload);

      return state;
    },

    [updateCategory.SUCCEEDED]: (state, { payload, args }) => {
      const index = state.categories.entries.findIndex(
        item => item.id === args.id
      );

      if (state.categories.entries[index].parentId !== payload.parentId) {
        state.categories.entries.splice(index, 1);
      } else {
        Object.keys(payload).forEach(key => {
          state.categories.entries[index][key] = payload[key];
        });
      }

      const allIndex = state.categories.all.findIndex(
        item => item.id === args.id
      );

      if (allIndex !== -1) {
        state.categories.all[allIndex] = payload;
      }

      return state;
    },

    [deleteCategory.SUCCEEDED]: (state, { payload }) => {
      state.categories.entries = state.categories.entries.filter(
        c => c.id !== payload
      );

      return state;
    },

    [update.SUCCEEDED]: (state, { payload, args }) => {
      const index = state.assets.entries.findIndex(a => a.id === payload.id);

      if (index !== -1) {
        if (
          state.assets.entries[index].category.id !== payload.category.id &&
          !args.isFromDetails
        ) {
          state.assets.entries = state.assets.entries.filter(
            (a, i) => i !== index
          );
        } else {
          state.assets.entries[index] = {
            ...state.assets.entries[index],
            ...payload
          };
        }
      }

      return state;
    },

    [partialUpdate.SUCCEEDED]: (state, { payload }) => {
      const index = state.assets.entries.findIndex(a => a.id === payload.id);

      if (index !== -1) {
        state.assets.entries[index] = {
          ...state.assets.entries[index],
          ...payload
        };
      }

      return state;
    },

    [remove.SUCCEEDED]: (state, { payload }) => {
      state.assets.totalItems -= 1;

      state.assets.entries = state.assets.entries.filter(a => a.id !== payload);

      return state;
    },

    // LOG & COMMENTS
    [fetchLog.SUCCEEDED]: (state, { payload }) => {
      const index = state.assets.entries.findIndex(a => a.id === payload.id);

      if (index !== -1) {
        state.assets.entries[index].log = {
          totalItems: payload.count,
          entries: payload.isFetchAfterChanges
            ? payload.results.reverse()
            : [
                ...payload.results.reverse(),
                ...((state.assets.entries[index].log || {}).entries || [])
              ]
        };
      }

      return state;
    },

    [sendComment.SUCCEEDED]: (state, { payload }) => {
      const index = state.assets.entries.findIndex(
        a => a.id === payload.assetId
      );

      if (index !== -1) {
        state.assets.entries[index].log = {
          totalItems: state.assets.entries[index].log.totalItems + 1,
          entries: [...state.assets.entries[index].log.entries, payload]
        };
      }

      return state;
    },

    // MANAGERS
    [fetchManagers.SUCCEEDED]: (state, { payload }) => {
      state.managers = payload.results;

      return state;
    },

    [addManager.SUCCEEDED]: (state, { payload }) => {
      state.categories.entries = state.categories.entries.map(category => {
        const newCategory = payload.categories.find(c => c.id === category.id);

        return newCategory ? { ...category, ...newCategory } : category;
      });

      return state;
    },

    [changeManager.SUCCEEDED]: (state, { payload, args }) => {
      const index = state.categories.entries.findIndex(
        c => c.id === args.categoryId
      );

      if (index !== -1) {
        state.categories.entries[index].assetManager = payload;
      }

      return state;
    },

    [changeAssetAttachments]: (state, { payload }) => {
      const index = state.assets.entries.findIndex(
        item => item.fileId === payload.fileId
      );

      if (index !== -1) {
        if (payload.isDelete) {
          state.assets.entries[index].fileList = (
            state.assets.entries[index].fileList || []
          ).filter(({ fileId }) => fileId !== payload.attachment.fileId);
        } else {
          state.assets.entries[index].fileList = [
            ...(state.assets.entries[index].fileList || []),
            payload.attachment
          ];
        }
      }

      return state;
    },

    // FILTERS
    [combineActions(
      clearFilter,
      setFilterSearch,
      setFilterResponsible,
      setFilterCreator,
      setFilterTag,
      setFilterRelations,
      setFilterCategories,
      setFilterMySubscriptions
    )]: state => {
      state.assets.entries = [];

      return state;
    },

    [clearFilter]: state => {
      state.filter = initialFilter;

      return state;
    },

    [setFilterRelations]: (state, { payload }) => {
      state.filter.relations[payload.key] = payload.value;

      return state;
    },

    [setFilterSearch]: (state, { payload }) => {
      state.filter.search = payload;

      return state;
    },

    [setFilterResponsible]: (state, { payload }) => {
      state.filter.responsible = payload;

      return state;
    },

    [setFilterCreator]: (state, { payload }) => {
      state.filter.creator = payload;

      return state;
    },

    [setFilterTag]: (state, { payload }) => {
      state.filter.tag = payload;

      return state;
    },

    [setFilterRange]: (state, { payload }) => {
      if (!payload.value || payload.value.filter(Boolean).length === 2) {
        state.assets.entries = [];
      }

      state.filter.range = payload;

      return state;
    },

    [setFilterCategories]: (state, { payload }) => {
      state.filter.category = payload;

      return state;
    },

    [setFilterManagers]: (state, { payload }) => {
      state.filter.manager = payload;

      return state;
    },

    [setFilterMySubscriptions]: (state, { payload }) => {
      state.filter.isMySubscriptions = payload;
    },

    [clearEntries]: state => {
      state.assets.totalItems = 0;
      state.categories.entries = [];
      state.assets.entries = [];

      return state;
    },

    [combineActions(subscribeAsset.SUCCEEDED, unsubscribeAsset.SUCCEEDED)]: (
      state,
      { payload }
    ) => {
      const index = state.assets.entries.findIndex(
        ({ id }) => id === Number(payload.entityId)
      );

      if (index !== -1) {
        state.assets.entries[index] = {
          ...state.assets.entries[index],
          isSubscribed: !state.assets.entries[index].isSubscribed
        };
      }

      return state;
    },

    [changeManageSubscribers.SUCCEEDED]: (state, { payload }) => {
      const { added, deleted, userId, entityType } = payload;

      if (entityType !== TYPE_ASSET) {
        return state;
      }

      if (added.includes(userId) || deleted.includes(userId)) {
        const index = state.assets.entries.findIndex(
          a => a.id === Number(payload.entityId)
        );

        if (index !== -1) {
          state.assets.entries[index] = {
            ...state.assets.entries[index],
            isSubscribed: !state.assets.entries[index].isSubscribed
          };
        }
      }

      return state;
    }
  },
  initialState
);
