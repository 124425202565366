import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import MainForm from 'components/assets-view/forms/main';
import Drawer from 'components/common/drawer';
import useManageSubscribers from 'components/common/subscriptions/use-manage-subscribers';

import { fetchLog, fetchOneLocal, update } from 'store/assets';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

const AssetEditorDrawer = ({ id, visible, onClose }) => {
  const dispatch = useDispatch();

  const [asset, setAsset] = useState(null);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('AddAsset');

  const { subscribeToNewAttachments } = useManageSubscribers();

  const fetch = async () => {
    try {
      setFetchLoading(true);

      const data = await dispatch(fetchOneLocal({ id }));
      setAsset(data);
    } finally {
      setFetchLoading(false);
    }
  };

  const onSubmit = async values => {
    const { attachmentFileList, ...assetData } = values;

    try {
      setIsLoading(true);

      await dispatch(update({ id, asset: assetData, isFromDetails: true }));

      await dispatch(
        fetchLog({
          id,
          isFetchAfterChanges: true,
          params: { limit: 10000, offset: 0 }
        })
      );

      subscribeToNewAttachments(attachmentFileList);

      onClose();

      setAsset(null);

      showNoticeMessage({ number: NOTICE_NUMBER.accessSettingsSaved });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (visible && (!asset || !(asset.fileList || []).length)) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Drawer
      title={<Drawer.Title>{t('EditAssetHeading')}</Drawer.Title>}
      width={591}
      visible={visible}
      destroyOnClose
      onClose={onClose}
      maskClosable={false}
    >
      <>
        {fetchLoading && !asset && <Spin />}

        {!fetchLoading && asset && (
          <MainForm
            values={asset}
            isLoading={isLoading}
            isEditor
            onSubmit={onSubmit}
          />
        )}
      </>
    </Drawer>
  );
};

export default AssetEditorDrawer;
