import React, { memo, useState } from 'react';
import { Typography, Progress, Tooltip } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  ACCEPTED_STATE_ACTUAL,
  ACCEPTED_STATE_AGREEMENT,
  ACCEPTED_STATE_DECLINED,
  ACCEPTED_STATE_IRRELEVANT
} from 'constants/index';

import Icon from 'components/common/icon';
import AttachmentActions from 'components/attachments-view/actions';
import FileTitle from 'components/attachments-view/views/all/components/file-title';

import formatBytes from 'utils/format-bytes';
import { getIsLink } from 'hooks/common/use-file-upload/types';
import useFileUpload from 'hooks/common/use-file-upload';

import AttachmentLink from './attachment-link';
import ActualIcon from './actual-icon';
import AgreementIcon from './agreement-icon';
import DeclinedIcon from './declined-icon';

import styles from './attachment.module.scss';

const { Paragraph, Text } = Typography;

export const getName = file =>
  file.name || (file.originFileObj || {}).name || file.title;

const Attachment = ({
  file,
  size,
  onDelete,
  onRename,
  changeFileList,
  isDisabled,
  className,
  actionsDeps,
  fileLinkTarget,
  isOpenViaLink,
  actions,
  subscribeCallback,
  unsubscribeCallback,
  hideSubscribersAction,
  onSubscribeToNewAttachment,
  allowManageSubscribers,
  changeManageSubscribersCallback,
  hideSubscribeAction
}) => {
  const { t } = useTranslation(['Common', 'Statuses']);

  const { cancelUpload } = useFileUpload();

  // it used to sync set file name if files are from storage
  const [changedName, setChangedName] = useState(null);

  const name = changedName || getName(file);
  const fileSize = file.size || file.fileSize;

  const renderProgress = () => {
    const formatedSize = formatBytes(fileSize);

    if (file.status !== 'error') {
      if (file.percent || !!file.originFileObj) {
        if (file.percent < 100) {
          return (
            <Progress
              showInfo={false}
              percent={file.percent || 0}
              size="small"
              title={`(${file.percent || 0}%)`}
              strokeWidth={3}
            />
          );
        }

        return (
          <span>
            {t('FileAttached')} •{' '}
            <span className={styles.size}>{formatedSize}</span>
          </span>
        );
      }

      return (
        fileSize > 0 && <span className={styles.size}>{formatedSize}</span>
      );
    }

    return (
      <div>
        <Text className={styles.text} type="danger">
          {t('Error')}
        </Text>
        <Text className={styles.size} type="danger">
          {(file.error || {}).message}
        </Text>
      </div>
    );
  };

  // cancelUpload check, if file is uploading - cancel upload and delete file else just delete file
  const handleDeleteFile = () => cancelUpload(file, onDelete);

  const isLink = getIsLink(file);

  const acceptedIconByState = {
    [ACCEPTED_STATE_ACTUAL]: {
      component: ActualIcon,
      tooltip: t('RelevantFileStatus', { ns: 'Statuses' })
    },
    [ACCEPTED_STATE_AGREEMENT]: {
      component: AgreementIcon,
      tooltip: t('WaitingForApproveFileStatus', { ns: 'Statuses' })
    },
    [ACCEPTED_STATE_DECLINED]: {
      component: DeclinedIcon,
      tooltip: t('DeclinedFileStatus', { ns: 'Statuses' })
    },
    [ACCEPTED_STATE_IRRELEVANT]: {
      component: DeclinedIcon,
      tooltip: t('NotRelevantFileStatus', { ns: 'Statuses' })
    }
  };

  if (isLink) {
    return <AttachmentLink url={file.title} />;
  }

  return (
    <figure className={classnames(styles.root, className, styles[size])}>
      <div className={styles.body}>
        <Icon type="attachment" size={16} className={styles.icon} />

        <div className={classnames(styles.content)}>
          <Paragraph
            className={styles.name}
            title={name}
            ellipsis={{ rows: 1 }}
          >
            <FileTitle
              isDisabled={!file.fileId}
              file={{ ...file, title: name }}
              target={fileLinkTarget}
              actionsDeps={actionsDeps}
              isOpenViaLink={isOpenViaLink}
            />
          </Paragraph>

          {renderProgress(file)}
        </div>
      </div>

      {file.acceptedState !== undefined && (
        <Tooltip
          mouseEnterDelay={0.5}
          placement="left"
          title={acceptedIconByState[file.acceptedState].tooltip}
        >
          <Icon
            component={acceptedIconByState[file.acceptedState].component}
            className={styles.acceptedIcon}
          />
        </Tooltip>
      )}

      <AttachmentActions
        file={{ ...file, title: name }}
        actionsDeps={actionsDeps}
        onDelete={onDelete ? handleDeleteFile : undefined}
        onRename={onRename}
        setChangedName={setChangedName}
        changeFileList={changeFileList}
        placement="topCenter"
        btnClassName={styles.actions}
        iconClassName={styles.actionsIcon}
        getPopupContainer={trigger => trigger.parentNode.parentNode}
        dropdownProps={{
          disabled: isDisabled
        }}
        customActions={actions}
        subscribeCallback={subscribeCallback}
        unsubscribeCallback={unsubscribeCallback}
        hideSubscribersAction={hideSubscribersAction}
        onSubscribeToNewAttachment={onSubscribeToNewAttachment}
        allowManageSubscribers={allowManageSubscribers}
        changeManageSubscribersCallback={changeManageSubscribersCallback}
        hideSubscribeAction={hideSubscribeAction}
      />
    </figure>
  );
};

Attachment.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fileId: PropTypes.string,
    fileSize: PropTypes.number,
    mimeType: PropTypes.string,
    createdAt: PropTypes.any,
    isAccepted: PropTypes.bool
  }),
  size: PropTypes.oneOf(['default', 'small', 'large']),
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onDelete: PropTypes.func,
  onRename: PropTypes.func,
  actionsDeps: PropTypes.shape({
    statusId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    contactId: PropTypes.number,
    taskId: PropTypes.number,
    assetId: PropTypes.number,
    sendCopyToComment: PropTypes.bool,
    messageUuid: PropTypes.string,
    allowDeleteFrom: PropTypes.bool,
    isPrivate: PropTypes.bool,
    entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    entityType: PropTypes.string,
    isFromOtherWorkspace: PropTypes.bool
  }),
  fileLinkTarget: PropTypes.string,
  actions: PropTypes.array,
  subscribeCallback: PropTypes.func,
  unsubscribeCallback: PropTypes.func,
  hideSubscribersAction: PropTypes.bool,
  onSubscribeToNewAttachment: PropTypes.func,
  changeManageSubscribersCallback: PropTypes.func,
  hideSubscribeAction: PropTypes.bool
};

Attachment.defaultProps = {
  file: undefined,
  size: 'large',
  className: undefined,
  isDisabled: false,
  onDelete: undefined,
  onRename: undefined,
  actionsDeps: {},
  fileLinkTarget: '_self',
  actions: undefined,
  subscribeCallback: () => {},
  unsubscribeCallback: () => {},
  hideSubscribersAction: false,
  onSubscribeToNewAttachment: undefined,
  changeManageSubscribersCallback: () => {},
  hideSubscribeAction: false
};

export default memo(Attachment);
