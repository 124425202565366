/* eslint-disable import/prefer-default-export */
import isEmpty from 'lodash/isEmpty';
import { uniq } from 'lodash';

import { getFileIdsV2 } from 'hooks/common/use-file-upload/get-file-ids';

export const getMembers = contractor => {
  if (!contractor || !contractor.responsible || isEmpty(contractor.members)) {
    return undefined;
  }

  const responsibleId = contractor.responsible.id;

  return uniq([...contractor.members, responsibleId]);
};

// This function described all allowed fields to create orderStatus (and transforms they)
export const transformFormSubmittedValues = ({
  values,
  isShortForm,
  t = string => string
} = {}) => {
  if (!values || isEmpty(values)) {
    return {};
  }

  const shortFormFields = {
    title: values.title,
    kind: values.kind.value || values.kind,
    content: values.content && values.content.description,
    fileList: getFileIdsV2(values.fileList),
    signatoryId: values.signatory ? values.signatory.value : null,
    contractor: values.contractor
      ? {
          id: values.contractor.value,
          employeeId: values.contractor.label.employee,
          workspaceId:
            values.contractor.label.employee &&
            values.contractor.label.company.workspace
              ? values.contractor.label.company.workspace.id
              : null
        }
      : undefined,
    price: values.price,
    currency: values.price ? values.currency.value : undefined,
    measurementUnit: values.price
      ? t('CostTypeOrder', { ns: 'Order' })
      : undefined,
    deadlineAt: values.deadlineAt,
    relations: values.relations,
    attachmentFileList: values.fileList || [],
    members:
      (values.contractor &&
        values.contractor.label &&
        getMembers(values.contractor.label)) ||
      (values.members && values.members) ||
      []
  };

  if (isShortForm) {
    return shortFormFields;
  }

  return {
    // kind - set in form wrap component
    // responsibleId - set in createOrderStatus action
    ...shortFormFields,
    location: values.location,
    locationExact: values.locationExact,
    startAt: values.startAt,
    relations: values.relations,
    tags: values.tags,
    // additional fields for submit function
    initialTags: values.initialTags,
    isFromTemplate: values.isFromTemplate,
    templateId: values.templateId
  };
};
