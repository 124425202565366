import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ManageSubscribersWrapper from 'components/common/subscriptions/manage-subscribers-wrapper';

import Attachment from './attachment';
import DownloadAllFiles from './download-all-files';

import styles from './attachments.module.scss';

export const Attachments = ({
  className,
  style,
  fileList,
  changeFileList,
  attachmentProps,
  actionsDeps,
  classLastElement,
  rootClassName,
  showDownloadAllButton,
  subscribeCallback,
  unsubscribeCallback,
  onChangeFileList,
  changeManageSubscribersCallback
}) => {
  if (!(fileList || []).length) {
    return null;
  }

  const allowDownloadAll = showDownloadAllButton && (fileList || []).length > 1;

  return (
    <div className={classnames(styles.root, rootClassName)}>
      {allowDownloadAll && <DownloadAllFiles fileList={fileList} />}

      <div className={classnames(styles.attachments, className)} style={style}>
        {fileList.map((file, i) => (
          <Fragment key={file.id || file.uid || file.uuid}>
            <Attachment
              file={{ ...file, orderId: actionsDeps.orderId }}
              actionsDeps={actionsDeps}
              {...attachmentProps}
              changeFileList={changeFileList}
              className={classnames(styles.file, attachmentProps.className, {
                [classLastElement]: fileList.length - 1 === i
              })}
              subscribeCallback={subscribeCallback}
              unsubscribeCallback={unsubscribeCallback}
              changeManageSubscribersCallback={changeManageSubscribersCallback}
            />

            <ManageSubscribersWrapper
              attachmentProps={attachmentProps}
              file={file}
              fileList={fileList}
              onChangeFileList={onChangeFileList}
              index={i}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
};

Attachments.propTypes = {
  className: PropTypes.string,
  attachmentProps: PropTypes.object,
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      uid: PropTypes.string,
      name: PropTypes.string,
      contentType: PropTypes.string,
      type: PropTypes.string,
      status: PropTypes.string,
      error: PropTypes.any,
      size: PropTypes.number,
      url: PropTypes.string
    })
  ),
  classLastElement: PropTypes.string,
  actionsDeps: PropTypes.shape({
    statusId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    contactId: PropTypes.number,
    taskId: PropTypes.number,
    assetId: PropTypes.number,
    sendCopyToComment: PropTypes.bool,
    messageUuid: PropTypes.string,
    allowDeleteFrom: PropTypes.bool,
    isPrivate: PropTypes.bool,
    entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    entityType: PropTypes.string,
    isFromOtherWorkspace: PropTypes.bool
  }),
  changeFileList: PropTypes.func,
  rootClassName: PropTypes.string,
  showDownloadAllButton: PropTypes.bool,
  subscribeCallback: PropTypes.func,
  unsubscribeCallback: PropTypes.func,
  changeManageSubscribersCallback: PropTypes.func
};

Attachments.defaultProps = {
  className: undefined,
  attachmentProps: {},
  fileList: [],
  actionsDeps: {},
  classLastElement: '',
  changeFileList: () => {},
  rootClassName: undefined,
  showDownloadAllButton: false,
  subscribeCallback: () => {},
  unsubscribeCallback: () => {},
  changeManageSubscribersCallback: () => {}
};

export default memo(Attachments);
